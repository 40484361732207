<template>
    <div class="holder">
        <div class="form-holder">
            <form @submit.prevent="changePassword">
                <div class="form-group">
                    <label for="exampleInputPassword1">Old Password</label>
                    <input type="password" v-model="form.old_password" class="form-control" id="exampleInputPassword1">
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword1">New Password</label>
                    <input type="password" v-model="form.new_password" class="form-control" id="exampleInputPassword1">
                </div>
                <div class="form-group">
                    <label for="exampleInputPassword2">Confirm Password</label>
                    <input type="password" v-model="form.confirm_password" class="form-control" id="exampleInputPassword2">
                </div>
                <!-- <button type="submit" class="btn btn-primary">Change Password</button> -->
                <rm-base-button
                    text="Change Password"
                    class="my-1 d-block"
                    block
                    small
                    :loading="loading"
                    :disableButton="loading"
                    />
            </form>
        </div>
    </div>
</template>

<script>
    export default {
        data: () => ({
            form: {
                old_password: "",
                new_password: "",
                confirm_password: "",
            },
            loading: false
        }), 
        methods: {
            async changePassword(){
                try {
                    this.loading = true;
                    let res = await this.$store.dispatch("auth/changePin", this.form)
                } catch (err) {
                    this.loading = false;
                    this.form.old_password = ""
                } finally {
                    this.loading = false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .holder {
        width: 100%;
        height: 80vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .form-holder{
            width: 70%;
            background-color: #fff;
            border-radius: 20px;
            padding: 20px
        }
    }
</style>